<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
  @import "~@/assets/css/table.css";
</style>
<style type="text/css" scoped>
  .font-blue {
    cursor: pointer;
  }

  .msgLabel {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }

  .msgName {
    min-width: 117px;
    width: 117px;
    color: rgb(102, 101, 101)
  }

  .msgVal {
    color: rgb(21, 21, 21)
  }

  .custom-file-label::after {
    display: none;
  }

  .exam-progress {
    font-size: 12px;
  }
</style>

<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";

  import {
    getInfoStatic,
    refreshInfoStatic
  } from "@/api/statistics/statistics.js"
  import {
    getExamDetails,
    getMould,
    editExamRecord,
  } from "@/api/admin/exam/examRecord.js"
  import {
    creatKscjdak,
    creatKswjk,
    creatKshmdk,
  } from "@/api/admin/exam/createExamku.js"
  import {
    getDicts
  } from "@/api/common/dict.js";
  import YzSelect from "@/components/form/yzSelect.vue";
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      PageHeader,
      YzSelect
    },
    data() {
      return {
        title: "",
        items: [{
            text: "用户首页",
            href: "/admin"

          },
          {
            text: "考试信息",
            active: true
          }
        ],
        componentKey: 1,
        dict: {},
        printMould: [],
        form: {},
        flbm: "",
        ksbmbh: "",
        scope: "numOfMonth",
        dayNum: {},
        weekNum: {},
        monthNum: {},
        formDate: {},
        topData: {},
        dataBox: {},
        series: [{
          name: new Date().getFullYear(),
          type: "column",
          data: []
        }],
        chartOptions: {
          chart: {
            height: 280,
            type: "line",
            toolbar: {
              show: false
            }
          },
          stroke: {
            width: [0, 3],
            curve: "smooth"
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "20%"
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          colors: ["#5664d2", "#1cbb8c"],
          labels: [
            "3:00",
            "5:00",
            "7:00",
            "9:00",
            "11:00",
            "13:00",
            "15:00",
            "17:00",
            "19:00",
            "21:00",
            "23:00",
            "24:00"
          ]
        },
        examForm: {}
      };
    },
    computed: {
      topdd() {
        return [{
            title: "报名人数",
            icon: " icon-user-group",
            value: (this.topData.bmrs || 0) + "人",
            text: "缴费" + (this.topData.jfrs || 0) + "人"
          },
          {
            title: "已审核",
            icon: "icon-stLine-doc-edit-l",
            value: (this.topData.yshrs || 0) + "人",
            text: "待审" + (this.topData.dshrs || 0) + "人，未通过" + (this.topData.shwtgrs || 0) + "人，锁定" + (this.topData
              .sdrs || 0) + "人"
          },
          {
            title: "准考证",
            icon: "icon-newspaper-o",
            value: (this.topData.zkzrs || 0) + "人",
            text: "已下载" + (this.topData.zkzyxz || 0) + "人"

          }
        ]
      }
    },
    methods: {
      // 考试详情
      getDetail(ksbmbh) {
        getExamDetails(ksbmbh).then(res => {
          if (res.status) {
            this.examForm = res.data
            // console.log(res.data)
            this.flbm = this.examForm.flbm
            this.title = this.examForm.ksmc
            this.ksbmbh = this.examForm.ksbmbh
            this.form.shbm = res.data.sfzhid
            this.form.shmc = res.data.sfzhmc

          }
        })
      },
      // 改变启用状态
      changeQyzt(name, val) {
        let f = {
          sid: this.sid,
        }
        f[name] = val
        // console.log(f)
        editExamRecord(f).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '数据修改成功'
            });
          }
        })
      },
      getPrintMould() {
        getMould({
          mblx: "准考证"
        }).then(res => {
          if (res.status) {
            this.printMould = res.data
          }
        })
      },
      editExamForm(str) {
        if (str == 'jfzh') {
          this.formDate = {
            sfxyjf: this.examForm.sfxyjf,
            sfzhmc: this.form.shmc,
            sfzhid: this.form.shbm

          }
        }
        if (str == 'zkz') {
          this.formDate = {
            sfqyzkzys: this.examForm.sfqyzkzys,
            zkzysmc: this.examForm.zkzysmc,
            zkzysid: this.examForm.zkzysid
          }
        }
        this.formDate.sid = this.sid
        editExamRecord(this.formDate).then(res => {
          if (res.status) {
            this.$notify({
              type: 'success',
              message: '数据操作成功!',
              duration: 3000,
            })
          }
        })
      },
      //上传库
      uploadFile(name, title, module) {
        var _this = this;
        let func;
        console.log(name)
        if (!name) {
          if (module == "CJDAK") {
            func = creatKscjdak
          }
          if (module == 'WJK') {
            func = creatKswjk
          }
          if (module == 'HMDK') {
            func = creatKshmdk
          }
          console.log(func)
          func({
            ksbmbh: this.ksbmbh
          })
        }
        _this.$importModal().show({
          title: title,
          module: module,
          ksbmbh: this.ksbmbh
        })

      },

      // 切换统计类型
      chooseTrend(type) {
        this.scope = type
        this.series[0].data = this.dataBox[type];
        this.componentKey += 1;
      },
      // 详情统计
      getExamStatistics() {
        let statisticData = {}
        statisticData.ksbmbh = this.ksbmbh
        getInfoStatic(statisticData).then(res => {
          if (res.data.statistical) {
            this.topData = res.data.statistical
          }
          this.dayNum = res.data.numOfDay ? res.data.numOfDay : {}
          this.monthNum = res.data.numOfMonth ? res.data.numOfMonth : {}
          this.weekNum = res.data.numOfWeek ? res.data.numOfWeek : {}

          let box = ['numOfDay', 'numOfMonth', 'numOfWeek'];
          let need = {}
          box.forEach(k => {
            let main = res.data[k]
            need[k] = []
            if (main) {
              for (let i = 2; i < 24; i = i + 2) {
                need[k].push(main["query" + i])
              }
            }

          })
          this.dataBox = need
          this.$nextTick(() => {
            this.chooseTrend("numOfMonth")
          })

        })
      },

      refreshData() { //刷新统计数据
        let ksbmbh = this.ksbmbh
        this.$confirm('更新将会占用大量系统资源，请勿在考试报名期间更新，是否确认更新（更新期间请耐心等待）？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          refreshInfoStatic(ksbmbh).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据统计成功!'
              });
              this.getExamStatistics()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消刷新'
          });
        });

      },
      chooseDm() {
        let val = this.examForm.zkzysmc
        let obj = this.printMould.find(k => {
          return k.mbmc === val
        })
        this.examForm.zkzysid = obj.mid
        console.log(this.examForm.zkzysid, this.examForm.zkzysmc)

      },
    },
    mounted() {
      if (this.ksbmbh) {
        this.getDetail(this.ksbmbh);
      }
      getDicts("sh").then((res) => {
        this.dict = res;
      });
      this.getPrintMould()
      this.getExamStatistics()
    },
    created() {
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";
      this.ksbmbh = this.$route.query["ksbmbh"] ? this.$route.query["ksbmbh"] : "";
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <div class="row">
          <div class="col-md-4" v-for="(data, index) in topdd" :key="index">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
                    <h4 class="mb-0">{{data.value}}</h4>
                  </div>
                  <div class="text-info">
                    <i :class="`${data.icon} font-size-24 iconfont`"></i>
                  </div>
                </div>
              </div>
              <div class="card-body border-top py-3">
                <div class="text-truncate">
                  <span class="text-muted ml-2">{{data.text}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="float-right d-none d-md-inline-block">
              <div class="btn-group mb-2">
                <button type="button" class="btn btn-sm btn-light" :class="[this.scope=='numOfWeek'?'active':'']"
                  @click="chooseTrend('numOfWeek')">本周</button>
                <button type="button" class="btn btn-sm btn-light" :class="[this.scope=='numOfDay'?'active':'']"
                  @click="chooseTrend('numOfDay')">今天</button>
                <button type="button" class="btn btn-sm btn-light" :class="[this.scope=='numOfMonth'?'active':'']"
                  @click="chooseTrend('numOfMonth')">本月</button>
              </div>
            </div>
            <h4 class="card-title mb-4">报名趋势<el-button type="primary" plain size="mini" class="h30 ml-2"
                @click="refreshData">刷新统计数据
              </el-button>
            </h4>
            <div>
              <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
              <apexchart class="apex-charts" height="280" dir="ltr" :series="series" :key="componentKey"
                :options="chartOptions">
              </apexchart>
            </div>
          </div>

          <div class="card-body border-top text-center">
            <div class="row">
              <div class="col-sm-3">
                <div class="d-inline-flex">
                  <h5 class="mr-2">{{dayNum.allDay||0}}</h5>
                </div>
                <p class="text-muted text-truncate mb-0">今日报名

                </p>
              </div>
              <div class="col-sm-3">
                <div class="d-inline-flex">
                  <h5 class="mr-2">{{weekNum.allDay||0}}</h5>
                </div>
                <p class="text-muted text-truncate mb-0">本周报名

                </p>
              </div>
              <div class="col-sm-3">
                <div class="d-inline-flex">
                  <h5 class="mr-2">{{monthNum.allDay||0}}</h5>
                </div>
                <p class="text-muted text-truncate mb-0">本月报名

                </p>
              </div>
              <div class="col-sm-3">
                <div class="d-inline-flex">
                  <h5 class="mr-2">{{topData.bmrs||0}}</h5>
                </div>
                <p class="text-muted text-truncate mb-0">全部报名

                </p>
              </div>

            </div>
          </div>
        </div>
        <div class="card" style="min-height: 185px;">
          <div class="card-body">
            <h4 class="card-title mb-3">考试报名流程
              <span><a href=""><i class="iconfont icon-more head-more" id="more"></i></a>
                <b-tooltip target="more" placement="bottom">更多</b-tooltip>
              </span>
            </h4>
            <div class="d-flex  pt-2">
              <div class="flexList  w-20" v-show="examForm.sfxywsbm">
                <div class="exam-progress">
                  考生报名 </div>
                <div class="exam-progress-arr">
                  <i class=" m-2 iconfont icon-angle-double-right"></i>
                </div>
              </div>
              <div class="flexList  w-20" v-show="examForm.sfxyzgjgsh">
                <div class="exam-progress">
                  报到单位审核
                </div>
                <div class="exam-progress-arr">
                  <i class=" m-2 iconfont icon-angle-double-right"></i>
                </div>
              </div>
              <div class="flexList  w-20" v-show="examForm.jfqsfyxxgxx">
                <div class="exam-progress">
                  报名线上确认
                </div>
                <div class="exam-progress-arr">
                  <i class=" m-2 iconfont icon-angle-double-right"></i>
                </div>
              </div>
              <div class="flexList  w-20" v-show="examForm.jfhsfqrbm">
                <div class="exam-progress">
                  考生缴费
                </div>
                <div class="exam-progress-arr">
                  <i class=" m-2 iconfont icon-angle-double-right"></i>
                </div>
              </div>

              <div class="flexList  w-20" v-show="examForm.dyzkzsfqr">
                <div class="exam-progress">
                  打印准考证
                </div>
                <div class="exam-progress-arr">
                  <i class=" m-2 iconfont icon-angle-double-right"></i>
                </div>
              </div>
              <div class="flexList  w-20">
                <div class="exam-progress">
                  考试、成绩查询
                </div>
              </div>






            </div>

          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card" style="min-height: 415px;">
          <div class="card-body">
            <h4 class="card-title">考试基本资料
              <span><a href=""><i class="iconfont icon-more head-more" id="more2"></i></a>
                <b-tooltip target="more2" placement="bottom">更多</b-tooltip>
              </span>
            </h4>
            <div class=" mt-3 ">
              <input type="hidden" class=" msgValInput w-30" value="" name="sid">
              <div class="msgContent flexList mb-2">
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName">考试类别</div>
                  <div class="msgVal" :title="examForm.flmc">
                    {{examForm.lbmc}}&nbsp;&nbsp;{{examForm.flmc}}
                  </div>
                </div>
                <div class="flexList w-100 msgLabel ">
                  <div class="msgName ">考试名称</div>
                  <div class="msgVal">
                    {{examForm.ksmc}}
                  </div>
                </div>
                <div class="flexList w-100 msgLabel">
                  <div class="msgName ">考试简称
                  </div>
                  <div class="msgVal">
                    {{examForm.ksjc}}
                  </div>
                </div>
                <div class="flexList w-100 msgLabel">
                  <div class="msgName ">滚动考试</div>
                  <div class="msgVal ">
                    <b-form-checkbox v-model="examForm.sfgdks" switch class="mt-1 switch-check"
                      @change="changeQyzt('sfgdks',examForm.sfgdks)">
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel">
                  <div class="msgName ">主管机构</div>
                  <div class="msgVal">
                    四川省建设厅

                  </div>
                </div>
                <div class="flexList w-100 msgLabel">
                  <div class="msgName ">网上缴费</div>
                  <div class="msgVal">
                    <b-form-checkbox v-model="examForm.jfsjzt" switch class="mt-1 switch-check"
                      @change="changeQyzt('jfsjzt',examForm.jfsjzt)">
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel">
                  <div class="msgName  ">缴费方式</div>
                  <div class="msgVal">
                    <span v-if="examForm.jffs==0">按报名标准收费</span>
                    <span v-if="examForm.jffs==1">按报考职位缴费</span>
                    <span v-if="examForm.jffs==2">按考试科目缴费</span>
                  </div>
                </div>

                <div class="flexList w-100 msgLabel">
                  <div class="msgName">允许上传照片</div>
                  <div class="msgVal flexList">
                    <b-form-checkbox v-model="examForm.sfsczp" switch class="mt-1 switch-check"
                      @change="changeQyzt('sfsczp',examForm.sfsczp)">
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel">
                  <div class="msgName  ">是否锁定</div>
                  <div class="msgVal">
                    <b-form-checkbox v-model="examForm.sfyxsd" switch class="mt-1 switch-check"
                      @change="changeQyzt('sfyxsd',examForm.sfyxsd)">
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel">
                  <div class="msgName  ">审核状态</div>
                  <div class="msgVal">
                    <b-form-checkbox v-model="examForm.cssjzt" switch class="mt-1 switch-check"
                      @change="changeQyzt('cssjzt',examForm.cssjzt)">
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center"><a
                :href="`/admin/examEnroll?sid=${examForm.sid}&ksbmbh=${examForm.ksbmbh}`"><button type="button"
                  class="btn btn-outline-info h30 mr-2">考试详细设置</button></a>
              <a :href="`/admin/enrollStatic?ksbmbh=${examForm.ksbmbh}&ksmc=${examForm.ksmc}`"
                v-if="examForm.kmxzfs==2"><button type="button" class="btn btn-info h30">报名数据统计</button></a>
            </div>

          </div>
        </div>

        <!--考试日程  -->
        <div class="card" style="min-height: 350px;">
          <div class="card-body">
            <h4 class="card-title border-dash pb-3">考试日程<span><a href=""><i class="iconfont icon-more head-more"
                    id="more3"></i></a>
                <b-tooltip target="more3" placement="bottom">更多</b-tooltip>
              </span></h4>
            <div class="mt-3">
              <div class="schedule-box w-100 flexList">
                <div class="exam-name"><span class="text-primary mr-1">
                    <i class="iconfont icon-riLine-arrow-drop-right-line"></i> 报名时间</span></div>
                <div class="exam-date">
                  <!-- <el-date-picker v-model="examForm.bmkssj"
                    @change="examForm.bmkssj=formatDateDetails(new Date($event))" class="w-100 h30" type="datetime"
                    placeholder="选择日期时间" default-time="12:00:00" size="mini"> </el-date-picker> -->
                  {{examForm.bmkssj}}
                </div>
                <div class="exam-minus"><i class="iconfont icon-mb-minus-sm"></i></div>
                <div class="exam-date mr-2">{{examForm.bmjssj}}</div>
                <div class="flexList">
                  <b-form-checkbox v-model="examForm.bmsjzt" switch class="switch-check"
                    @change="changeQyzt('bmsjzt',examForm.bmsjzt)">
                  </b-form-checkbox>
                  <div v-if="examForm.bmsjzt">
                    禁用
                  </div>
                  <div v-else>
                    启用
                  </div>
                </div>
              </div>
              <div class="schedule-box w-100 flexList">
                <div class="exam-name"><span class="text-primary mr-1">
                    <i class="iconfont icon-riLine-arrow-drop-right-line"></i> 初审时间</span></div>
                <div class="exam-date">{{examForm.cskssj}}</div>
                <div class="exam-minus"><i class="iconfont icon-mb-minus-sm"></i></div>
                <div class="exam-date mr-2">{{examForm.csjssj}}</div>
                <div class="flexList">
                  <b-form-checkbox v-model="examForm.cssjzt" switch class="switch-check"
                    @change="changeQyzt('cssjzt',examForm.cssjzt)">
                  </b-form-checkbox>
                  <div v-if="examForm.cssjzt">
                    禁用
                  </div>
                  <div v-else>
                    启用
                  </div>
                </div>
              </div>
              <div class="schedule-box w-100 flexList">
                <div class="exam-name"><span class="text-primary mr-1">
                    <i class="iconfont icon-riLine-arrow-drop-right-line"></i> 照片审核</span></div>
                <div class="exam-date">{{examForm.zpshkssj}}</div>
                <div class="exam-minus"><i class="iconfont icon-mb-minus-sm"></i></div>
                <div class="exam-date mr-2">{{examForm.zpshjssj}}</div>
                <div class="flexList">
                  <b-form-checkbox v-model="examForm.zpshsjzt" switch class="switch-check"
                    @change="changeQyzt('zpshsjzt',examForm.zpshsjzt)">
                  </b-form-checkbox>
                  <div v-if="examForm.zpshsjzt">
                    禁用
                  </div>
                  <div v-else>
                    启用
                  </div>
                </div>
              </div>
              <div class="schedule-box w-100 flexList">
                <div class="exam-name"><span class="text-primary mr-1">
                    <i class="iconfont icon-riLine-arrow-drop-right-line"></i> 缴费时间</span></div>
                <div class="exam-date">{{examForm.jfkssj}}</div>
                <div class="exam-minus"><i class="iconfont icon-mb-minus-sm"></i></div>
                <div class="exam-date mr-2">{{examForm.jfjssj}}</div>
                <div class="flexList">
                  <b-form-checkbox v-model="examForm.jfsjzt" switch class="switch-check"
                    @change="changeQyzt('jfsjzt',examForm.jfsjzt)">
                  </b-form-checkbox>
                  <div v-if="examForm.jfsjzt">
                    禁用
                  </div>
                  <div v-else>
                    启用
                  </div>
                </div>
              </div>
              <div class="schedule-box w-100 flexList">
                <div class="exam-name"><span class="text-primary mr-1">
                    <i class="iconfont icon-riLine-arrow-drop-right-line"></i> 准考证打印</span></div>
                <div class="exam-date">{{examForm.zkzdykssj}}</div>
                <div class="exam-minus"><i class="iconfont icon-mb-minus-sm"></i></div>
                <div class="exam-date mr-2">{{examForm.zkzdyjssj}}</div>
                <div class="flexList">
                  <b-form-checkbox v-model="examForm.zkzdysjzt" switch class="switch-check"
                    @change="changeQyzt('zkzdysjzt',examForm.zkzdysjzt)">
                  </b-form-checkbox>
                  <div v-if="examForm.zkzdysjzt">
                    禁用
                  </div>
                  <div v-else>
                    启用
                  </div>
                </div>
              </div>
              <div class="schedule-box w-100 flexList">
                <div class="exam-name"><span class="text-primary mr-1">
                    <i class="iconfont icon-riLine-arrow-drop-right-line"></i> 考试时间</span></div>
                <div class="exam-date">{{examForm.kskssj}}</div>
                <div class="exam-minus"><i class="iconfont icon-mb-minus-sm"></i></div>
                <div class="exam-date mr-2">{{examForm.ksjssj}}</div>
                <div class="flexList">
                  <b-form-checkbox v-model="examForm.kssjzt" switch class="switch-check"
                    @change="changeQyzt('kssjzt',examForm.kssjzt)">
                  </b-form-checkbox>
                  <div v-if="examForm.kssjzt">
                    禁用
                  </div>
                  <div v-else>
                    启用
                  </div>
                </div>
              </div>
              <div class="schedule-box w-100 flexList">
                <div class="exam-name"><span class="text-primary mr-1">
                    <i class="iconfont icon-riLine-arrow-drop-right-line"></i> 成绩公布</span></div>
                <div class="exam-date">{{examForm.cjgbkssj}}</div>
                <div class="exam-minus"><i class="iconfont icon-mb-minus-sm"></i></div>
                <div class="exam-date mr-2">{{examForm.cjgbjssj}}</div>
                <div class="flexList">
                  <b-form-checkbox v-model="examForm.cjgbsjzt" switch class="switch-check"
                    @change="changeQyzt('cjgbsjzt',examForm.cjgbsjzt)">
                  </b-form-checkbox>
                  <div v-if="examForm.cjgbsjzt">
                    禁用
                  </div>
                  <div v-else>
                    启用
                  </div>
                </div>
              </div>
              <div class="schedule-box w-100 flexList">
                <div class="exam-name"><span class="text-primary mr-1">
                    <i class="iconfont icon-riLine-arrow-drop-right-line"></i>成绩复核 </span></div>
                <div class="exam-date">{{examForm.cjfhkssj}}</div>
                <div class="exam-minus"><i class="iconfont icon-mb-minus-sm"></i></div>
                <div class="exam-date mr-2">{{examForm.cjfhjssj}}</div>
                <div class="flexList">
                  <b-form-checkbox v-model="examForm.cjfhsjzt" switch class="switch-check"
                    @change="changeQyzt('cjfhsjzt',examForm.cjfhsjzt)">
                  </b-form-checkbox>
                  <div v-if="examForm.cjfhsjzt">
                    禁用
                  </div>
                  <div v-else>
                    启用
                  </div>
                </div>
              </div>
              <div class="schedule-box w-100 flexList">
                <div class="exam-name"><span class="text-primary mr-1">
                    <i class="iconfont icon-riLine-arrow-drop-right-line"></i> 资格复查</span></div>
                <div class="exam-date">{{examForm.zgfckssj}}</div>
                <div class="exam-minus"><i class="iconfont icon-mb-minus-sm"></i></div>
                <div class="exam-date mr-2">{{examForm.zgfcjssj}}</div>
                <div class="flexList">
                  <b-form-checkbox v-model="examForm.zgfcsjzt" switch class="switch-check"
                    @change="changeQyzt('zgfcsjzt',examForm.zgfcsjzt)">
                  </b-form-checkbox>
                  <div v-if="examForm.zgfcsjzt">
                    禁用
                  </div>
                  <div v-else>
                    启用
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-12">
            <div class="card" style="min-height: 300px;">
              <div class="card-body">
                <h4 class="card-title pb-3"><span class="mr-2">考试关联设置</span>
                </h4>
                <div class="table-responsive border">
                  <table class="table  light-table table-hover ">
                    <thead class="thead-light">
                      <tr>
                        <th>参数设置</th>
                        <th style="width: 8%;">是否启用</th>
                        <th>启用名称</th>
                        <th style="width: 35%;">重新设置 </th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr>
                        <td>报名模板 </td>
                        <td>
                          <b-form-checkbox v-model="examForm.zpshkssj1" switch class="mt-1 switch-check">
                          </b-form-checkbox>
                        </td>
                        <td>四川省人民检察院机关公开招聘聘用制书记员报名表</td>
                        <td class="flexList">
                          <select name="" class="form-control w-20 form-select mr-2">
                            <option value=""> 考试二级分类</option>
                          </select>

                          <div class="table-btn"><button type="button" class="btn btn-info w-sm h30">设置</button></div>
                        </td>

                      </tr> -->


                      <tr>
                        <td>成绩档案库 </td>
                        <td>
                          <b-form-checkbox v-model="examForm.sfqycjdak" :disabled="!examForm.cjdakbm" switch
                            class="mt-1 switch-check" @change="changeQyzt('sfqycjdak',examForm.sfqycjdak)">
                          </b-form-checkbox>
                        </td>
                        <td>{{examForm.cjdakbm}}</td>
                        <td class="flexList">
                          <div class="d-flex">
                            <input type="text" disabled placeholder="请选择文件" class=" form-control h30 w-50  mr-2"
                              v-model="examForm.cjdakbm" />
                            <el-button size="small" plain class="flexList  h30  " style="width: 110px;"
                              @click="uploadFile(examForm.cjdakbm,'考试档案库导入','CJDAK')"><i
                                class="iconfont mr-1 icon-shangchuan"></i>上传文件</el-button>
                          </div>
                          <a :href="API_URL+'/yethan/register/examRecord/cjdak/downloadTemplate?ytoken=' + getStore('accessToken')"
                            target="_blank">
                            <div class="red-font">只支持dbf，点击下载模板</div>
                          </a>
                        </td>

                      </tr>
                      <tr>
                        <td>违纪库 </td>
                        <td>
                          <b-form-checkbox v-model="examForm.sfqywjk" switch :disabled="!examForm.wjkbm"
                            class="mt-1 switch-check" @change="changeQyzt('sfqywjk',examForm.sfqywjk)">
                          </b-form-checkbox>
                        </td>
                        <td>{{examForm.wjkbm}}</td>
                        <td class="flexList">
                          <div class="d-flex ">
                            <input type="text" disabled placeholder="请选择文件" class=" form-control h30 w-50  mr-2"
                              v-model="examForm.wjkbm" />
                            <el-button size="small" plain class="flexList  h30  " style="width: 110px;"
                              @click="uploadFile(examForm.wjkbm,'考试违纪库导入','WJK')"><i
                                class="iconfont mr-1 icon-shangchuan"></i>上传文件</el-button>
                          </div>
                          <a :href="API_URL+'/yethan/register/examRecord/wjk/downloadTemplate?ytoken=' + getStore('accessToken')"
                            target="_blank">
                            <div class="red-font">只支持dbf，点击下载模板</div>
                          </a>
                        </td>

                      </tr>
                      <tr>
                        <td>黑名单库 </td>
                        <td>
                          <b-form-checkbox v-model="examForm.sfqyhmdk" :disabled="!examForm.hmdkbm" switch
                            class="mt-1 switch-check" @change="changeQyzt('sfqyhmdk',examForm.sfqyhmdk)">
                          </b-form-checkbox>
                        </td>
                        <td>{{examForm.hmdkbm}}</td>
                        <td class="flexList">
                          <div class="d-flex ">
                            <input type="text" disabled placeholder="请选择文件" class=" form-control h30 w-50  mr-2"
                              v-model="examForm.hmdkbm" />
                            <el-button size="small" plain class="flexList  h30  " style="width: 110px;"
                              @click="uploadFile(examForm.hmdkbm,'考试黑名单库导入','HMDK')"><i
                                class="iconfont mr-1 icon-shangchuan"></i>上传文件</el-button>
                          </div>
                          <a :href="API_URL+'/yethan/register/examRecord/hmdk/downloadTemplate?ytoken=' + getStore('accessToken')"
                            target="_blank">
                            <div class="red-font">只支持dbf，点击下载模板</div>
                          </a>
                        </td>

                      </tr>
                      <!-- <tr>
                        <td>免试申报模板 </td>
                        <td>
                          <b-form-checkbox v-model="examForm.sfqymssqmb" switch class="mt-1 switch-check">
                          </b-form-checkbox>
                        </td>
                        <td>{{examForm.mssqmbmc}}</td>
                        <td class="flexList">
                          <select name="" class="form-control w-40  form-select mr-2" v-model="examForm.mssqmbmc">
                            <option value="" disabled>请选择免试模板</option>
                            <option value="item.mssqmbid" v-for="(item,index) in msList" :key="index">
                              {{item.msdm}}
                            </option>
                          </select>
                          <div class="table-btn"><button type="button" class="btn btn-info w-sm h30"
                              @click="editExamForm('msmb')">设置</button></div>
                        </td>

                      </tr> -->
                      <tr>
                        <td>缴费账户 </td>
                        <td>
                          <b-form-checkbox v-model="examForm.sfxyjf" switch class="mt-1 switch-check"
                            @change="changeQyzt('sfxyjf',examForm.sfxyjf)">
                          </b-form-checkbox>
                        </td>
                        <td>{{examForm.sfzhmc}}</td>
                        <td class="flexList">
                          <yz-select class="h30 mr-2 w-40" :dict="dict.sh" :bound="['shbm', 'shmc']" v-model="form">
                          </yz-select>

                          <div class="table-btn">
                            <!-- <el-button size="small" type="primary" class=" h30  ">设置</el-button> -->
                            <button type="button" class="btn btn-info w-sm  h30"
                              @click="editExamForm('jfzh')">设置</button>
                          </div>
                        </td>

                      </tr>
                      <tr>
                        <td>准考证样式 </td>
                        <td>
                          <b-form-checkbox v-model="examForm.sfqyzkzys" switch class="mt-1 switch-check"
                            @change="changeQyzt('sfqyzkzys',examForm.sfqyzkzys)">
                          </b-form-checkbox>
                        </td>
                        <td>{{examForm.zkzysmc}}</td>
                        <td class="flexList">
                          <div></div>
                          <select name="" class="form-control  form-select mr-2 w-40" v-model="examForm.zkzysmc"
                            @change="chooseDm()">
                            <option value="" disabled>请选择样式</option>
                            <option :value="item.mbmc" v-for="(item,index) in printMould" :key="index"
                              :label="item.mbmc">
                              {{item.mbmc}}
                            </option>
                          </select>
                          <div class="table-btn"><button type="button" class="btn btn-info w-sm h30"
                              @click="editExamForm('zkz')">设置</button></div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>


              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
  </Layout>
</template>
